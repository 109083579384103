import { UAParser } from 'ua-parser-js';

const parser = new UAParser();
const { name: browser } = parser.getBrowser();
const { name: os } = parser.getOS();
const { type: deviceType } = parser.getDevice();

const isAndroid = os === 'Android';
const isMobile = deviceType === 'mobile';
const isIOS = os === 'iOS';
const isMacOS = os === 'Mac OS';
const isWebkit = browser === 'WebKit';
const isAppleDeviceWebApp = isWebkit && (isIOS || isMacOS);
const isKAKAO = browser === 'KAKAOTALK';
const isNAVER = browser === 'NAVER';
const isKakaoOrNaver = isKAKAO || isNAVER;
const isSamsungInternet = browser === 'Samsung Internet';

export {
  isAndroid,
  isMobile,
  isIOS,
  isMacOS,
  isWebkit,
  isAppleDeviceWebApp,
  isKAKAO,
  isNAVER,
  isKakaoOrNaver,
  isSamsungInternet,
  parser,
};
